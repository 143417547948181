import {StaticImage} from "gatsby-plugin-image";
import {useCallback, useState} from "react";
import * as React from "react";
import MailIcon from "../../assets/svg/subscribe/mail.svg";
import SuccessIcon from "../../assets/svg/subscribe/success.svg";
import Input from "./Fields/Input";
import Form from "./index";
import * as subscribeFormStyles from "./subscribe.module.css";

const fields = [
    {
        name: "email",
        render({control, register, error, key}) {
            const rules = {
                pattern: {
                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
                    message: "Введен некорректный email адрес",
                },
            };

            return (
                <Input
                    register={register}
                    control={control}
                    type="email"
                    name={this.name}
                    placeholder="Введите ваш email"
                    required="Email обязателен к заполнению"
                    rules={rules}
                    error={error}
                    key={key}
                    flat
                />
            );
        },
    },
];

const SubscribeForm = () => {
    const [status, setStatus] = useState("waiting");

    const handler = useCallback(async (data) => {
        const response = await fetch(`${process.env.GATSBY_API_URL}/mailer/subscribe`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                email: data.email,
            }),
        });
        const json = await response.json();

        if (json.success) {
            setStatus("success");

            return;
        }

        if (response.status === 409) {
            setStatus("alreadySubscribed");
        } else {
            setStatus("error");
        }
    }, []);

    return (
        <div className={subscribeFormStyles.subscribeForm}>
            {status === "waiting" && (
                <div className={subscribeFormStyles.subscribeForm__content}>
                    <div>
                        <MailIcon />
                    </div>
                    <h4 className={subscribeFormStyles.subscribeForm__title}>Подпишитесь на новостную рассылку</h4>
                    <Form fields={fields} onSubmit={handler} buttonText="Подписаться" />
                </div>
            )}
            {status === "error" && (
                <div className={subscribeFormStyles.subscribeForm__success}>
                    <div>
                        <MailIcon />
                    </div>
                    <h4 className={subscribeFormStyles.subscribeForm__title}>
                        Произошла ошибка при отправке, попробуйте позже
                    </h4>
                </div>
            )}
            {status === "success" && (
                <div className={subscribeFormStyles.subscribeForm__success}>
                    <div>
                        <SuccessIcon />
                    </div>
                    <h4 className={subscribeFormStyles.subscribeForm__title}>
                        Спасибо! Вы успешно подписались на нашу рассылку
                    </h4>
                </div>
            )}
            {status === "alreadySubscribed" && (
                <div className={subscribeFormStyles.subscribeForm__success}>
                    <div>
                        <SuccessIcon />
                    </div>
                    <h4 className={subscribeFormStyles.subscribeForm__title}>Вы уже подписаны на новостную рассылку</h4>
                </div>
            )}
            <StaticImage
                src="../../assets/images/form/subscribe/blur.png"
                alt=""
                placeholder="none"
                layout="fixed"
                className={subscribeFormStyles.subscribeForm__gradient}
            />
        </div>
    );
};

export default SubscribeForm;
