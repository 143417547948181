import {navigate} from "@reach/router";
import {useCallback, useEffect, useMemo, useState} from "react";

function useBlogFilters(locationSearch) {
    const searchParams = useMemo(() => new URLSearchParams(locationSearch), [locationSearch]);

    const [searchString, setSearchString] = useState(searchParams.get("search") || "");
    const [searchResults, setSearchResults] = useState([]);

    const updateSearchResults = useCallback((search) => {
        if (search.length === 0) {
            setSearchResults([]);
        }

        // eslint-disable-next-line no-underscore-dangle
        const lunrInstance = window.__LUNR__;
        if (search && lunrInstance) {
            // eslint-disable-next-line no-underscore-dangle
            lunrInstance.__loaded
                .then((lunr) => {
                    const languages = ["en", "ru"];
                    const ids = languages.reduce((currentIds, lang) => {
                        const refs = lunr[lang].index.search(search);
                        const posts = refs.map(({ref}) => lunr[lang].store[ref].id);

                        return [...new Set([...currentIds, ...posts])];
                    }, []);

                    setSearchResults(ids);
                })
                .catch((e) => console.error(e));
        }
    }, []);

    const [activeFilters, setActiveFilters] = useState({
        author: Number(searchParams.get("author")) || 0,
        products: searchParams.get("products")?.split(",") || [],
        tags: searchParams.get("tags")?.split(",") || [],
        industries: searchParams.get("industries")?.split(",") || [],
    });

    const [haveActiveFilters, setHaveActiveFilters] = useState(
        activeFilters.author !== 0 ||
            activeFilters.products.length > 0 ||
            activeFilters.tags.length > 0 ||
            activeFilters.industries.length > 0,
    );
    const [activeFiltersCount, setActiveFiltersCount] = useState(() => {
        return (
            Number(!!activeFilters.author) +
            activeFilters.products.length +
            activeFilters.tags.length +
            activeFilters.industries.length
        );
    });

    const updateURL = useCallback((search, filters) => {
        const searchQuery = search ? `search=${search}` : "";
        const productsQuery = filters.products.length > 0 ? `products=${filters.products}` : "";
        const tagsQuery = filters.tags.length > 0 ? `tags=${filters.tags}` : "";
        const industriesQuery = filters.industries.length > 0 ? `industries=${filters.industries}` : "";
        const authorQuery = filters.author !== 0 ? `author=${filters.author}` : "";

        const query = `?${[authorQuery, searchQuery, productsQuery, tagsQuery, industriesQuery]
            .filter(Boolean)
            .join("&")}`;

        navigate(query).catch((e) => console.error(e));
    }, []);

    const toggleFilter = useCallback(
        (type, value) => {
            setActiveFilters((currentActiveFilters) => {
                const newActiveFilters = {};

                if (type === "author") {
                    newActiveFilters.author = value;
                } else {
                    newActiveFilters.author = currentActiveFilters.author;
                }

                if (type === "products") {
                    if (!currentActiveFilters.products.includes(value)) {
                        newActiveFilters.products = [...currentActiveFilters.products, value];
                    } else {
                        newActiveFilters.products = currentActiveFilters.products.filter((entry) => entry !== value);
                    }
                } else {
                    newActiveFilters.products = currentActiveFilters.products;
                }

                if (type === "tags") {
                    if (!currentActiveFilters.tags.includes(value)) {
                        newActiveFilters.tags = [...currentActiveFilters.tags, value];
                    } else {
                        newActiveFilters.tags = currentActiveFilters.tags.filter((entry) => entry !== value);
                    }
                } else {
                    newActiveFilters.tags = currentActiveFilters.tags;
                }

                if (type === "industries") {
                    if (!currentActiveFilters.industries.includes(value)) {
                        newActiveFilters.industries = [...currentActiveFilters.industries, value];
                    } else {
                        newActiveFilters.industries = currentActiveFilters.industries.filter(
                            (entry) => entry !== value,
                        );
                    }
                } else {
                    newActiveFilters.industries = currentActiveFilters.industries;
                }

                setHaveActiveFilters(
                    newActiveFilters.author !== 0 ||
                        newActiveFilters.products.length > 0 ||
                        newActiveFilters.tags.length > 0 ||
                        newActiveFilters.industries.length > 0,
                );

                setActiveFiltersCount(
                    Number(!!newActiveFilters.author) +
                        newActiveFilters.products.length +
                        newActiveFilters.tags.length +
                        newActiveFilters.industries.length,
                );

                updateURL(searchString, newActiveFilters);

                return newActiveFilters;
            });
        },
        [searchString, updateURL],
    );

    const resetFilters = useCallback(() => {
        updateURL(searchString, {author: 0, products: [], tags: [], industries: []});
    }, [searchString, updateURL]);

    const handleSearchReset = useCallback(() => {
        setSearchString("");
        updateSearchResults("");

        updateURL("", activeFilters);
    }, [activeFilters, updateSearchResults, updateURL]);

    const handleSearchSubmit = useCallback(
        (e) => {
            e.preventDefault();

            const {target} = e;
            const field = target.querySelector(`input[name="search"]`);

            updateSearchResults(field.value);
            updateURL(field.value, activeFilters);
        },
        [activeFilters, updateSearchResults, updateURL],
    );

    useEffect(() => {
        setSearchString(searchParams.get("search") || "");
        updateSearchResults(searchParams.get("search") || "");

        const newActiveFilters = {
            author: Number(searchParams.get("author")) || 0,
            products: searchParams.get("products")?.split(",") || [],
            tags: searchParams.get("tags")?.split(",") || [],
            industries: searchParams.get("industries")?.split(",") || [],
        };

        setActiveFilters(newActiveFilters);

        setHaveActiveFilters(
            newActiveFilters.author !== 0 ||
                newActiveFilters.products.length > 0 ||
                newActiveFilters.tags.length > 0 ||
                newActiveFilters.industries.length > 0,
        );

        setActiveFiltersCount(
            Number(!!newActiveFilters.author) +
                newActiveFilters.products.length +
                newActiveFilters.tags.length +
                newActiveFilters.industries.length,
        );
    }, [searchParams, updateSearchResults]);

    useEffect(() => {
        updateSearchResults(searchString);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        activeFilters,
        haveActiveFilters,
        activeFiltersCount,
        toggleFilter,
        resetFilters,
        searchResults,
        searchString,
        setSearchString,
        handleSearchSubmit,
        handleSearchReset,
    };
}

export default useBlogFilters;
