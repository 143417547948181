import PropTypes from "prop-types";
import * as React from "react";
import Box from "../../../assets/svg/checkbox/box.svg";
import Check from "../../../assets/svg/checkbox/check.svg";
import * as checkboxStyles from "./checkbox.module.css";
import * as fieldStyles from "./field.module.css";

export const Checkbox = React.forwardRef(({name, label, checked, ...other}, ref) => {
    return (
        <label className={checkboxStyles.checkbox}>
            <input type="checkbox" name={name} checked={checked} ref={ref} {...other} />
            <span className={checkboxStyles.checkbox__icon}>
                <Box className={checkboxStyles.checkbox__box} />
                <Check className={checkboxStyles.checkbox__check} />
            </span>
            <span className={checkboxStyles.checkbox__label}>{label}</span>
        </label>
    );
});

const CheckboxField = ({register, label, name, value, checked, required, disabled, error}) => {
    return (
        <div className={fieldStyles.field}>
            <label className={checkboxStyles.checkbox}>
                <input
                    type="checkbox"
                    value={value}
                    name={name}
                    defaultChecked={checked}
                    {...register(name, {required, disabled})}
                />
                <span className={checkboxStyles.checkbox__icon}>
                    <Box className={checkboxStyles.checkbox__box} />
                    <Check className={checkboxStyles.checkbox__check} />
                </span>
                <span className={checkboxStyles.checkbox__label}>{label}</span>
            </label>
            {error && (
                <p className={fieldStyles.field__error}>
                    {error?.message || `Поле${label ? ` ${label}` : ""} обязательно к заполнению`}
                </p>
            )}
        </div>
    );
};

CheckboxField.defaultProps = {
    required: false,
    label: null,
    value: "",
    checked: false,
    disabled: false,
    error: null,
};

CheckboxField.propTypes = {
    register: PropTypes.func.isRequired,
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    checked: PropTypes.bool,
    required: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.exact({
            value: PropTypes.bool,
            message: PropTypes.string,
        }),
    ]),
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.shape({message: PropTypes.string}), PropTypes.string]),
};

export default CheckboxField;
