import classNames from "classnames";
import PropTypes from "prop-types";
import * as React from "react";
import Container from "../Container";
import SectionTitle from "./Title";
import SectionDescription from "./Description";
import {section, section__content as sectionContent} from "./section.module.css";

const Section = React.forwardRef(({id, title, desc, children, className, containerClassName}, ref) => {
    return (
        <section ref={ref} className={classNames(section, className)} id={id}>
            <Container className={containerClassName}>
                {title && <SectionTitle>{title}</SectionTitle>}
                {desc && <SectionDescription>{desc}</SectionDescription>}
                <div className={sectionContent}>{children}</div>
            </Container>
        </section>
    );
});

Section.defaultProps = {
    title: "",
    desc: "",
    children: null,
    className: "",
    containerClassName: "",
};

Section.propTypes = {
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    desc: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    children: PropTypes.node,
    id: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
};

export default Section;
