import {useMediaQuery} from "@react-hook/media-query";
import classNames from "classnames/bind";
import {graphql} from "gatsby";
import {incline} from "lvovich";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {BlogJsonLd} from "gatsby-plugin-next-seo";
import useBlogFilters from "../../hooks/useBlogFilters";
import {capitalize} from "../../utils/string";
import ResetIcon from "../../assets/svg/close.svg";
import SearchIcon from "../../assets/svg/search.svg";
import ArrowIcon from "../../assets/svg/arrow-current-color.svg";
import Layout from "../../components/Layout/layout";
import Section from "../../components/Layout/Section";
import SectionTitle from "../../components/Layout/Section/Title";
import SectionDescription from "../../components/Layout/Section/Description";
import Seo from "../../components/SEO/SEO";
import ArticleCard from "../../components/Article/Card";
import {Checkbox} from "../../components/Form/Fields/Checkbox";
import Subscribe from "../../components/Form/Subscribe";
import Button from "../../components/UI/Button";
import * as blogStyles from "./blog.module.css";

const cx = classNames.bind(blogStyles);

const PER_PAGE_COUNT = 8;

const needToShowSubscribeForm = (idx, arrLength) => {
    const cardsInTenRows = 30;
    const isFourArticle = idx + 1 === 4;
    const fourthArticleInPack = (idx + 1) % cardsInTenRows === 4;
    const lessThanFiveArticles = arrLength < 5 && idx === arrLength;

    return isFourArticle || fourthArticleInPack || lessThanFiveArticles;
};

const BlogPage = ({data: {articles, authors, pageData}, location}) => {
    const {
        activeFilters,
        haveActiveFilters,
        activeFiltersCount,
        toggleFilter,
        resetFilters,
        searchResults,
        searchString,
        setSearchString,
        handleSearchSubmit,
        handleSearchReset,
    } = useBlogFilters(location.search);

    const isMobile = useMediaQuery("(max-width: 768px)");
    const [showProductsFilters, setShowProductsFilters] = useState(activeFilters.products.length > 0);
    const [showIndustriesFilters, setShowIndustriesFilters] = useState(activeFilters.industries.length > 0);
    const [showMobileFilters, setShowMobileFilters] = useState(false);
    const [showMoreTags, setShowMoreTags] = useState(false);
    const [authorName] = useState(() => {
        if (activeFilters.author === 0) {
            return "";
        }

        const authorData = authors.edges.find(({node: {id}}) => id === activeFilters.author);
        if (!authorData) {
            return "";
        }

        const {
            node: {name},
        } = authorData;

        const [firstName = "", lastName = ""] = name.split(" ");
        const inclinedNameParts = incline({first: firstName, last: lastName}, "genitive");

        return `${inclinedNameParts.first} ${inclinedNameParts.last}`;
    });

    const filteredArticles = useMemo(() => {
        return () => {
            const isFitAuthor = (articleAuthors) => {
                return (
                    activeFilters.author === 0 ||
                    articleAuthors.some(({id: authorId}) => authorId === activeFilters.author)
                );
            };

            const isFitSearch = (id) => searchResults.length === 0 || searchResults.includes(id);

            const isFitProducts = (productSlug) => {
                return activeFilters.products.length === 0 || activeFilters.products.includes(productSlug);
            };

            const isFitTags = (tags) => {
                return activeFilters.tags.length === 0 || tags.some((tag) => activeFilters.tags.includes(tag.slug));
            };

            const isFitIndustries = (industries) => {
                return (
                    activeFilters.industries.length === 0 ||
                    industries.some((industry) => activeFilters.industries.includes(industry.slug))
                );
            };

            return articles.edges.filter(
                ({node: {id, tags: articleTags, industries: articleIndustries, product, authors: articleAuthors}}) => {
                    return (
                        isFitSearch(id) &&
                        isFitAuthor(articleAuthors) &&
                        isFitProducts(product?.slug) &&
                        isFitTags(articleTags) &&
                        isFitIndustries(articleIndustries)
                    );
                },
            );
        };
    }, [activeFilters, articles, searchResults]);

    const [lazyLoadData, updateLazyLoadData] = useState({
        page: 1,
        maxPages: Math.ceil(filteredArticles().length / PER_PAGE_COUNT),
    });

    useEffect(() => {
        updateLazyLoadData((currentValue) => {
            if (
                currentValue.page === 1 &&
                currentValue.maxPages === Math.ceil(filteredArticles().length / PER_PAGE_COUNT)
            ) {
                return currentValue;
            }

            return {
                page: 1,
                maxPages: Math.ceil(filteredArticles().length / PER_PAGE_COUNT),
            };
        });
    }, [filteredArticles]);

    const adjustPage = useCallback(() => {
        updateLazyLoadData((currentData) => {
            const newPage = currentData.page + 1;

            return {...currentData, page: newPage};
        });
    }, [updateLazyLoadData]);

    const posts = filteredArticles()
        .slice(0, 8)
        .map((article) => ({headline: article.node.title}));

    const getTags = useCallback(() => {
        const tagsData = [];

        if (activeFilters.author !== 0) {
            tagsData.push({
                content: `Статьи ${authorName}`,
                label: `Выбранный автор ${authorName}`,
                onClick: () => toggleFilter("author", 0),
                key: `author_${activeFilters.author}`,
            });
        }

        activeFilters.products.forEach((type, idx) => {
            tagsData.push({
                content: capitalize(type),
                key: `product_${idx}`,
                label: `Выбранный продукт ${type}`,
                onClick: () => toggleFilter("products", type),
            });
        });

        pageData.tags
            .filter(({article_tag: {slug}}) => activeFilters.tags.includes(slug))
            .forEach(({article_tag: {title, slug, id}}) => {
                tagsData.push({
                    content: title,
                    key: `tag_${id}`,
                    label: `Выбранный тэг ${title}`,
                    onClick: () => toggleFilter("tags", slug),
                });
            });

        pageData.industries
            .filter(({article_industry: {slug}}) => activeFilters.industries.includes(slug))
            .forEach(({article_industry: {title, slug, id}}) => {
                tagsData.push({
                    content: title,
                    key: `industries_${id}`,
                    label: `Выбранная отрасль ${title}`,
                    onClick: () => toggleFilter("industries", slug),
                });
            });

        return tagsData;
    }, [activeFilters, authorName, pageData.tags, pageData.industries, toggleFilter]);

    const onFiltersClickHandler = useCallback(() => {
        setShowMobileFilters((val) => !val);
    }, []);

    const {seo, products, tags, industries} = pageData;

    return (
        <Layout safeOverflow needFiltersButton onFiltersClick={onFiltersClickHandler}>
            <Seo seo={seo} />
            <BlogJsonLd url={location.href} headline="Блог" posts={posts} authorName="ITSM 365" />
            <Section>
                <div className={cx({blog: true})}>
                    <form
                        className={cx({blog__search: true})}
                        action="/blog"
                        role="search"
                        onReset={handleSearchReset}
                        onSubmit={handleSearchSubmit}
                    >
                        <SearchIcon className={cx({blog__searchIcon: true})} />
                        <input
                            className={cx({blog__searchField: true})}
                            type="text"
                            aria-label="Поиск по блогу"
                            name="search"
                            placeholder="Поиск по блогу"
                            value={searchString}
                            onChange={({target}) => setSearchString(target.value)}
                        />
                        <button
                            className={cx({blog__searchReset: true})}
                            type="reset"
                            aria-label="Сбросить поисковый запрос"
                        >
                            <ResetIcon />
                        </button>
                    </form>
                    <div className={cx({blog__filters: true, opened: showMobileFilters})}>
                        <div className={cx({blog__filtersHeader: true})}>
                            <p>Настройка тэгов</p>
                            <button type="button" onClick={onFiltersClickHandler}>
                                <ResetIcon />
                            </button>
                        </div>
                        <div>
                            <div>
                                <button
                                    type="button"
                                    className={cx({
                                        blog__filtersButton: true,
                                        blog__filtersButton_active: showProductsFilters,
                                    })}
                                    onClick={() => setShowProductsFilters((prev) => !prev)}
                                >
                                    <span>Выбрать продукт</span>
                                    <ArrowIcon />
                                </button>
                                {showProductsFilters &&
                                    products.map(({product}) => {
                                        if (!product || !product.slug) {
                                            return null;
                                        }

                                        const {slug, id} = product;

                                        return (
                                            <Checkbox
                                                name="filter_product"
                                                label={capitalize(slug)}
                                                key={id}
                                                checked={activeFilters.products.includes(slug)}
                                                onChange={() => toggleFilter("products", slug)}
                                            />
                                        );
                                    })}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className={cx({
                                        blog__filtersButton: true,
                                        blog__filtersButton_active: showIndustriesFilters,
                                    })}
                                    onClick={() => setShowIndustriesFilters((prev) => !prev)}
                                >
                                    <span>Выбрать отрасль</span>
                                    <ArrowIcon />
                                </button>
                                {showIndustriesFilters &&
                                    industries.map(({article_industry: {title, slug, id}}) => {
                                        return (
                                            <Checkbox
                                                name="filter_industry"
                                                label={title}
                                                key={id}
                                                checked={activeFilters.industries.includes(slug)}
                                                onChange={() => toggleFilter("industries", slug)}
                                            />
                                        );
                                    })}
                            </div>
                            <div>
                                {tags.map(({article_tag: {title, slug, id}}) => {
                                    return (
                                        <Checkbox
                                            name="filter_tag"
                                            label={title}
                                            key={id}
                                            checked={activeFilters.tags.includes(slug)}
                                            onChange={() => toggleFilter("tags", slug)}
                                        />
                                    );
                                })}
                            </div>
                            <div>
                                <Button variant="primary" onClick={onFiltersClickHandler}>
                                    Применить
                                </Button>
                                <Button
                                    onClick={() => {
                                        resetFilters();
                                        onFiltersClickHandler();
                                    }}
                                >
                                    Отменить фильтры
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={cx({blog__feedWrapper: true})}>
                        <SectionTitle>
                            {activeFilters.author !== 0 && `Статьи ${authorName}`}{" "}
                            {activeFilters.author === 0 &&
                                (activeFilters.products.length > 0 || activeFilters.tags.length > 0
                                    ? "Ваша лента по тегам"
                                    : "Лента материалов")}
                        </SectionTitle>
                        <div className={cx({blog__activeTagsWrapper: true})}>
                            <div className={cx({blog__activeTags: true})}>
                                {getTags().map(({content, label, key, onClick}, idx) => {
                                    return (
                                        <button
                                            type="button"
                                            className={cx({
                                                blog__tag: true,
                                                blog__tag_hidden: isMobile && !showMoreTags && idx >= 3,
                                            })}
                                            data-content={content}
                                            key={key}
                                            aria-label={label}
                                            onClick={onClick}
                                        >
                                            <ResetIcon />
                                        </button>
                                    );
                                })}
                                {isMobile && !showMoreTags && activeFiltersCount > 0 && activeFiltersCount - 3 > 0 && (
                                    <button
                                        type="button"
                                        className={cx({blog__tag: true, blog__tag_showMore: true})}
                                        data-content={`+${activeFiltersCount - 3}`}
                                        aria-label={`Показать остальные ${activeFiltersCount - 3} тэгов`}
                                        onClick={() => setShowMoreTags(true)}
                                    />
                                )}
                            </div>
                            {haveActiveFilters && (
                                <button
                                    type="button"
                                    className={cx({blog__activeTagsResetButton: true})}
                                    data-content="Сбросить"
                                    aria-label="Сбросить выбранные фильтры"
                                    onClick={resetFilters}
                                >
                                    <ResetIcon />
                                </button>
                            )}
                        </div>
                        {filteredArticles().length === 0 && (
                            <SectionDescription>По данному запросу найдено 0 материалов</SectionDescription>
                        )}
                        <div className={cx({blog__feed: true})}>
                            {filteredArticles()
                                .slice(0, lazyLoadData.page * PER_PAGE_COUNT)
                                .map(({node: article}, idx, arr) => {
                                    const articleData = {
                                        title: article.title,
                                        description: article.description,
                                        date: new Date(article.published_at),
                                        readingTime: article.readingTime,
                                        url: `/blog/${article.slug}`,
                                        featuredImage: article.featuredImage,
                                    };

                                    if (needToShowSubscribeForm(idx, arr.length)) {
                                        return (
                                            <React.Fragment key={`fragment-${idx}`}>
                                                <ArticleCard {...articleData} key={article.id} />
                                                <div
                                                    className={cx({blog__subscribeForm: true})}
                                                    key={`subscribe-form-${idx}`}
                                                >
                                                    <Subscribe />
                                                </div>
                                            </React.Fragment>
                                        );
                                    }

                                    return <ArticleCard {...articleData} key={article.id} />;
                                })}{" "}
                            {lazyLoadData.page < lazyLoadData.maxPages && (
                                <div className={cx({blog__loadMoreButton: true})}>
                                    <Button type="button" variant="primary" onClick={adjustPage}>
                                        Показать больше материалов
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Section>
        </Layout>
    );
};

export const pageQuery = graphql`
    query {
        pageData: strapiBlog {
            seo {
                title
                description
                openGraph {
                    title
                    description
                    image {
                        url
                    }
                }
            }
            products {
                product {
                    id
                    slug
                }
            }
            tags {
                article_tag {
                    id
                    title
                    slug
                }
            }
            industries {
                article_industry {
                    id
                    title
                    slug
                }
            }
        }
        authors: allStrapiAuthors {
            edges {
                node {
                    name
                    id: strapiId
                }
            }
        }
        articles: allStrapiArticles(sort: {fields: published_at, order: DESC}) {
            edges {
                node {
                    id
                    title
                    description
                    readingTime
                    published_at
                    slug
                    tags: articleTags {
                        slug
                    }
                    industries: articleIndustries {
                        slug
                    }
                    authors {
                        id
                    }
                    product {
                        slug
                    }
                    featuredImage {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(width: 632)
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default BlogPage;
